import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const TOGGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="tog">
      <ul className="breadcrumb">
        <li>
          <Link to="/tog/">Tower of God: New World</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/tog/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/tog/categories/category_intro.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            Everything you need to know about Tower of God: New World gacha
            game.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Tower of God New World is a mobile game published by Netmarble. It is
          a faithful reaction of the original webtoon, full of action-packed
          combat - Relive exhilarating scenes from the webtoon. Experience Bam
          and his friends' thrilling battles in 3D!
        </p>
        <p>
          While the game belongs to the popular idle gacha game genre, it
          introduces several systems that make it stand out - one of them is the
          Shinsu link which allows you to level up your account, instead of
          leveling the characters one by one. This allows you to quickly adapt
          to the opponents you face, without the need to reset who you're using!
        </p>
        <p>
          The game will launch on the <strong>26th of July worldwide</strong>,
          but if you live in Phillipines you can already try out the game (but
          only on Android).
        </p>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official forum:{' '}
            <a
              href="https://forum.netmarble.com/towerofgod_en"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.gg/tognewworld"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@tog_new_world"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.netmarble.tog&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/tower-of-god-new-world/id1599435437"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
        <SectionHeader title="Official images" />
        <Row xs={1} xl={2}>
          <Col>
            <StaticImage src="../../../images/tog/promo.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/tog/promo2.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/tog/promo3.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/tog/promo4.webp" alt="Guides" />
          </Col>
        </Row>
        <SectionHeader title="Gameplay" />
        <p>
          If you want to check how the game looks in action, below video shows
          the early game (no commentary, so you can focus on what you see):
        </p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="-spqtyk16ow" />
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
        <SectionHeader title="Information" />
        <p>
          We are still progressing through the game, testing it out, but below
          you can find the information we already managed to find.
        </p>
        <h5>Characters</h5>
        <ul>
          <li>
            there are around <strong>70 characters</strong> available on release
            (15 R, 15 SR, 37 SSR, and 6 SSR+ - though why they didn't name them
            UR I don't know),
          </li>
          <li>
            each character has a <strong>Color</strong> (Red, Green, Blue,
            Yellow, Purple), <strong>Class</strong> (Support, Tank, Assassin,
            Warrior, Mage, Ranged), and <strong>Position</strong> (Fisherman,
            Wave Controller etc - so the roles from the webtoon),
          </li>
          <li>
            each character has a <strong>Unique Weapon</strong> that gives them
            additional stats/passives - you unlock the weapon for free after
            reaching a certain level of <strong>Proficiency </strong>(which
            increases by simply using the character),
          </li>
          <li>
            you don't level characters, but your account using a system called{' '}
            <strong>Shinsu Link</strong> - you level 5 'spots' and this
            translates to your team. Whoever you place in those spots will get
            automatically leveled to the value. So it's basically Sync system
            from other idle games, but enabled from level 1. And everything
            happens automatically - you don't need to reset characters or stuff.
            Whoever you place in your team will get leveled up,
          </li>
          <li>
            lower rarity units are actually worth using and can compete with SSR
            ones,
          </li>
          <li>
            you get Gems for both increasing <strong>Proficiency</strong> (so
            using a character in combat) and for <strong>Rapport</strong>{' '}
            (gifting them stuff) - maxing 1 character in both will give you{' '}
            <strong>2000 Gems</strong> (you need 3000 for a 10x pull). No matter
            the rarity of the character, you can get the 2000 Gems.
          </li>
        </ul>
        <h5>Gacha</h5>
        <ul>
          <li>
            combat is built around the classic elemental system (classic Red
            &gt; Green &gt; Blue triangle, while Yellow and Purple counter each
            other),
          </li>
          <li>
            the elemental system is pretty important as you deal 100% more
            damage and take 50% reduced damage when fighting against a Color
            opposite to yours,
          </li>
          <li>
            characters use their sub-skills themselves (based on internal
            cooldown), but you can use their Ultimate attacks when you want (but
            you can't pick the target manually),
          </li>
          <li>you build teams consisting of 5 characters.</li>
        </ul>
        <h5>Gacha</h5>
        <ul>
          <li>
            the rate to get an SSR character is <strong>4.7%</strong>
          </li>
          <li>
            there's a <strong>Wishlist system</strong> that allows you to pick 5
            SSR characters and you have a higher chance of pulling them. All
            Colors are available here, including Yellow and Purple (so it seems
            they aren't treated like typical Light/Dark from other games),
          </li>
          <li>
            after doing{' '}
            <strong>100 pulls you can pick ANY SSR character</strong> you want
            and the game shows you the % of other players picking the
            characters,
          </li>
          <li>
            after doing{' '}
            <strong>200 pulls you will get a random SSR+ character</strong>, but
            if you're not happy with who you've got you can reroll who you got,
            but only once - and you can't go back to the previous one,
          </li>
          <li>
            for pulling dupes (of any rarity) you're getting some kind of
            generic Purple Orb. The higher rarity of the character, the more
            Orbs (R=1, SR=10, SSR=50, SSR+=200),
          </li>
          <li>
            dupes allow you to increase the skill levels of the characters and
            every 3 dupes you get an additional bonus. In total you need 15
            dupes to max the skills of a character.
          </li>
        </ul>
        <h5>Other information</h5>
        <ul>
          <li>
            the game is an <strong>idle game</strong> which means there's no
            Stamina and your progress is limited by the ability to finish the
            current stage. Once you get stuck, you simply wait to get resources
            and upgrade your characters to get stronger,
          </li>
          <li>
            the game follows the story of Tower of God, but the developers
            worked with the author of the webcomic (SIU) to create side-stories
            that expand the universe,
          </li>
          <li>
            on top of Adventure Mode which tracks your progress, there are
            additional modes available: Conquest Battle (lab-like mode, but you
            can auto clear it), Trial Arena (typical tower climber), Battle Room
            (bosses to kill that drop loot), Arena (PVP),
          </li>
          <li>
            A guild system is also available and there's already content for
            guilds,
          </li>
          <li>
            The game is server-based, but there's just 1 server available atm.
            Still, pretty sure that once it fills, they will open another,
          </li>
          <li>
            Guilds and friends are cross server and only the leaderboard and PVP
            grouping are server-based.
          </li>
        </ul>
        <SectionHeader title="Video" />
        <p>For more information check this great video from Volkin:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="4UfBzni0Z7o" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default TOGGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Tower of God: New World | Prydwen Institute"
    description="Everything you need to know about Tower of God: New World gacha game."
    game="tog"
  />
);
